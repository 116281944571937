@font-face {
  font-family: 'Agipo Bold Condensed';
  font-style: bold;
  font-weight: 400;
  src: url('./fonts/AgipoBoldCond.woff2'); /* IE9 Compat Modes */

}

@font-face {
  font-family: 'Forma DJR Bold Italic';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/FormaDJRText-BoldItalic.woff2'); /* IE9 Compat Modes */

}

@font-face {
  font-family: 'Forma DJR Bold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/FormaDJRText-Bold.woff2'); /* IE9 Compat Modes */

}

:root {
  --primaryFont: 'Agipo Bold Condensed';
  --bodyBoldFont: 'Forma DJR Bold';
  --bodyItalicFont: 'Forma DJR Bold Italic';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
