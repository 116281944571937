.page-layout {
  background-color: #ff57e7;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;
  padding-top: 100px;

  transition: width 1s cubic-bezier(.83,0,.17,1);
}

.content-container {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

.page-layout__full {
  width: 100%;
}

.nav-container {
  display: flex;
  justify-content: center;
  /* overflow: auto; */
}

.page-layout__half {
  width: 50%;
}

@media screen and (max-width: 768px){
  .page-layout {
    padding-top: 40px;
    width: 100%; 
    right: 0;
    left: 0;
    top:25%;
    height: auto;
  }
}

@media screen and (max-width: 1100px){
  .page-layout {
    padding-top: 40px;
  }

  .content-container {
    padding: 40px;
  }
}

