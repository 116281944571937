
.logo {
  width: 250px;
  height: 250px;
  margin-top: 10px;
  margin-left: 10px;

}
@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 8s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    display:none;
  }
}