.title {
  font-family: var(--primaryFont);
  font-size: 36px;
}

.link{
  text-decoration: none;
  color: black;
}

.bio-name{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  align-items: center;
  font-family: var(--primaryFont);
  font-size: 30px;
  padding-left: 40px;
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 768px){
  .bio-name {
    font-size: 20px;
    padding-left: 25px;
  }
}