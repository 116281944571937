.background {
  background-image: url('./images/website_image_Blue_Bleed.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;

  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 768px) {
  .background {

    top: 0;
    right: 0;
    bottom: 75%;
    left: 0;
  }
}