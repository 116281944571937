.title {
  font-family: var(--primaryFont);
  font-size: 36px;
  text-align: left;
}

.link{
  text-decoration: none;
  color: black;
}

.about-body{
  text-align: left;
}

.email-link{
  text-align: left;
}

.about{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.grid-container{
  display: inline-grid;
  text-align: center; 
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
}

.grid-item{
  text-align: center; 
  padding: auto;
}

@media screen and (max-width: 768px){
  .grid-container {
    text-align: left;
  }
}

.about-click-link{
  font-weight: bold;
  text-decoration: none;
  color: black;
}