
.manifesto {
  font-family: var(--primaryFont);
  font-size: 36px;
}

.link{
  text-decoration: none;
  font-family: var(--primaryFont);
  font-size: 20px;
  color: black;
}

.email-link{
  text-decoration: none;
  font-family: var(--primaryFont);
  font-size: 30px;
  color: black;
}