.title {
  font-family: var(--primaryFont);
  font-size: 36px;
}

.link{
  text-decoration: none;
  color: black;
}

.action-body{

}

.body-title{
  font-weight: bold;
}

.click-link{
  text-decoration: none;
  font-weight: normal;
  color: black;
}