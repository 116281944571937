.nav {
  display: inline-flex;
  flex-direction: row; 

  /* flex: 1; */

  transition: flex 1s cubic-bezier(.83,0,.17,1);
  overflow-x: auto;

  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 11px;
}

.nav__centered {
  /*justify-content: center;*/
}

.nav > *:not(:last-child) {
  margin-right: 50px;
}

.nav-link{
  font-size: 24pt;
  text-decoration: none;
  color: black;
}
.nav-link__active {
  font-weight: bold;
}


.nav::-webkit-scrollbar{
  height: 5px;
}

.nav::-webkit-scrollbar-track{
  background: none;
}

.nav::-webkit-scrollbar-thumb{
  border-left: solid 100px transparent;
  box-shadow: inset 0 0 3px 3px rgb(163, 57, 147);
}

@media screen and (max-width: 1100px) {
  .nav-link {
    font-size: 20pt;
  }

  .nav {
    padding-left: 30px;
    padding-right: 30px;
  }

  .nav::-webkit-scrollbar-thumb{
    border-left: solid 30px transparent;
  }
}

@media screen and (hover: none){
  .nav::-webkit-scrollbar{
    display:none;
  }
}

