.profile-image{
  object-fit: cover;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

@media screen and (max-width: 768px){
  .profile-image {
    object-fit: cover;
    height: 90px;
    width: 90px;
    border-radius: 50%;
  }
}