.podcast{
  margin-left: 50px;
}
.podcast-title{
  font-family: var(--primaryFont);
  font-size: 36px;
  text-align: center;
}

.bold{
  font-weight: bold;
}

.link{
  text-decoration: none;
  color: black;
}

.grid{
  display: inline-grid;
  text-align: center; 
  grid-template-columns: 50% 50%;
  grid-gap: 1px;
}

.grid-item {
  text-align: left; 
  padding: 10px;
}

.podcast-image{
  padding-left: 50px;
  width: 60%;
  height: auto;
}

.podcast-body{
  margin-left: -100px;
  margin-top: -8px;
}

@media screen and (max-width: 768px){
  .podcast-title{
    font-family: var(--primaryFont);
    font-size: 35px;
    text-align: left;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: left;
    padding-left: auto;
    grid-row-gap: auto;
  }
  
  .grid-item {
    
  }
  
  .podcast-image{
    width: 60%;
    height: auto;
    padding-left: -10px;
  }
  
  .podcast-body{
    padding-left: 50px;
  }

}