.title {
  font-family: var(--primaryFont);
  font-size: 36px;
}

.fullbio{
  text-align: center;
}

.link{
  text-decoration: none;
  color: black;
}
/* 
/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
} */

@media screen and (max-width: 768px){
  .bio-name {
    font-size: 20px;
    padding-left: 25px;
  }
  .column{
    width: 100%;
  }
}
